import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'helpers/baseQuery'
import { TUser, TUserEdit } from 'types/User'

export const profileAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery,
  endpoints: (build) => ({
    edit: build.mutation<TUser, TUserEdit>({
      query: ({ id, ...body }) => {
        return {
          url: `Customer/profile/${id}/edit`,
          method: 'post',
          body,
        }
      },
    }),
    searchUser: build.mutation<boolean, string>({
      query: (phone) => {
        return {
          url: `Customer/search/${phone}`,
        }
      },
    }),
  }),
})
