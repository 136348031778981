import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'

import type { RootState } from 'store'
import { authAPI, profileAPI } from 'services'
import { TUser } from 'types/User.d';

const initialState: {
  user: TUser | null
  token: string | null
} = {
  user: null,
  token: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null
      state.token = null
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(authAPI.endpoints.login.matchFulfilled, authAPI.endpoints.confirm.matchFulfilled),
        (state, { payload }: PayloadAction<{ customer: TUser, token: string }>) => {
          state.user = payload.customer
          state.token = payload.token
        }
      )
      .addMatcher(
        profileAPI.endpoints.edit.matchFulfilled,
        (state, { payload }: PayloadAction<TUser>) => {
          state.user = payload
        }
      )
  }
})

export const { logout } = authSlice.actions
export const selectUser = (state: RootState) => state.auth.user
export default authSlice.reducer