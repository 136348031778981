import { baseQuery } from 'helpers/baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react'

import { TUser } from 'types/User.d';

interface TAuth {
  phone: string
}

interface TSignin extends TAuth {
  password: string
}

interface TConfirm extends TAuth {
  phone: string
  code: string
}

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery,
  endpoints: build => ({
    login: build.mutation<{ customer: TUser, token: string }, TSignin>({
      query: (body: TSignin) => ({
        url: 'Customer/auth',
        method: 'post',
        body,
      })
    }),
    signup: build.mutation({
      query: (body: TAuth) => ({
        url: 'Customer/signup',
        method: 'post',
        body,
      })
    }),
    confirm: build.mutation({
      query: (body: TConfirm) => ({
        url: `Customer/signup/confirm`,
        method: 'post',
        body,
      })
    }),
    passwordReset: build.mutation({
      query: (body: TAuth) => ({
        url: `Customer/pwd-reset`,
        method: 'post',
        body,
      })
    }),
  })
})