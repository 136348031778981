import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from 'helpers/baseQuery'

export type TPoint = {
  address: string
  workDays: number
  startWorkTime: string
  endWorkTime: string
  coordinates: {
    x: number
    y: number
  }
  cardLink: string | null
}

export const mapAPI = createApi({
  reducerPath: 'mapAPI',
  baseQuery,
  endpoints: (build) => ({
    getPoints: build.query<TPoint[], void>({
      query: () => ({
        url: 'settings/reception-point',
      }),
    }),
  }),
})
