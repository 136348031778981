import router from 'next/router';
import { orderAPI } from 'services';
import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from 'store'
import { TObjectID } from 'types/common';
import { ROUTES } from 'constants/routes';

export type TBasketItems = {
  [key: TObjectID]: number
}

type TBasket = {
  list: TBasketItems
}

const initialState: TBasket = {
  list: {}
}

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addItemToBasket: (state, { payload }: { payload: TObjectID }) => {
      state.list = {
        ...state.list,
        [payload]: 1
      }
    },
    incrementBasketItem: (state, { payload }: { payload: TObjectID }) => {
      state.list = {
        ...state.list,
        [payload]: state.list[payload] + 1
      }
    },
    setCountBasketItem: (state, { payload }: { payload: [TObjectID, number ]}) => {
      state.list = {
        ...state.list,
        [payload[0]]: payload[1]
      }
    },
    decrementBasketItem: (state, { payload }: { payload: TObjectID }) => {
      const value = state.list[payload]
      state.list = {
        ...state.list,
        [payload]: value ? value - 1 : 0
      }
    },
    deleteBasketItem: (state, { payload }: { payload: TObjectID }) => {
      delete state.list[payload]
    },
    setBasketItems: (state, { payload }: { payload: TBasketItems }) => {
      state.list = payload
    },
    clearBasket: (state) => {
      state.list = {}
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      orderAPI.endpoints.create.matchFulfilled,
      state => {
        state.list = {}
        router.push(ROUTES.orders)
        alert(
          'Заказ отправлен. Ближайший освободившийся оператор перезвонит вам для согласования адреса и времени забора вещей'
        )
      }
    )
  }
})

export const { addItemToBasket, incrementBasketItem, setCountBasketItem, decrementBasketItem, deleteBasketItem, setBasketItems, clearBasket } = basketSlice.actions
export const selectBasketItem = (id: number) => (state: RootState) => state.basket.list?.[id]
export const selectBasketItems = (state: RootState) => state.basket.list
export default basketSlice.reducer