import { baseQuery } from 'helpers/baseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

import { TCategory, TInfoClick } from 'types/Category'

export const categoryAPI = createApi({
  reducerPath: 'categoryAPI',
  baseQuery,
  endpoints: (build) => ({
    get: build.query<TCategory[], void>({
      query: (body) => ({
        url: 'Client/services/categories',
        body,
      }),
    }),
    infoClick: build.mutation<TInfoClick, TInfoClick>({
      query: (body) => ({
        url: `Client/categories/click`,
        method: 'post',
        body,
      }),
    }),
  }),
})
