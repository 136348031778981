import { baseQuery } from 'helpers/baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react'

import { TQa, TReview, TService } from 'types/Service';

export const serviceAPI = createApi({
  reducerPath: 'serviceAPI',
  baseQuery,
  endpoints: build => ({
    get: build.query<TService[], void>({
      query: body => ({
        url: 'Client/services/active',
        body
      })
    }),
    settings: build.query<{
      deliveryPrice: number,
      freeDeliveryAmount: number
    }, void>({
      query: body => ({
        url: 'Client/settings/delivery',
        body
      })
    }),
    localDate: build.query<{from: string, to: string}, string>({
      query: (localDate) => ({
        url: `/settings/worktime?localDate=${localDate}`,
      })
    }),
    getReviews: build.query<{ reviews: TReview[], pages: number }, void>({
      query: () => ({
        url: `/Settings/reviews/all`
      })
    }),
    getQa: build.query<TQa[],void>({
      query: () => ({
        url: 'Settings/faqs'
      })
    })
  })
})