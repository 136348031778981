import { baseQuery } from 'helpers/baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react'

export type SearchAddressDTO = {
  kind: string
  lat: number
  lon: number
  name: string
  name_short: string
}

export const addressAPI = createApi({
  reducerPath: 'addressAPI',
  baseQuery,
  endpoints: build => ({
    get: build.mutation<SearchAddressDTO[], string>({
      query: (part: string) => ({
        url: 'Address',
        method: 'GET',
        params: {
          part
        }
      })
    }),
  })
})