export const ROUTES = {
  home: '/',

  profile: '/profile',
  prices: '/uslugi',
  points: '/punkty',
  bussiness: '/bussiness',
  orders: '/orders',
  saved: '/saved',
  stars: '/stars',
  vacancies: '/vacancy',
  stock: '/stock',

  callback: '/callback',
  basket: '/cart',
  franchise: '/franshiza',
  search: '/search',
  policy: '/policy',

  signUp: '/join',
  signIn: '/login',
  signOut: '/logout',
  confirm: '/confirm',
  aboutUs: '/about',
  qa: '/qa',
  reviews: '/reviews'
}