import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { Reducer } from 'redux'

import { loadState } from 'store/browser-storage'
import { unauthenticatedMiddleware } from 'helpers/baseQuery'
import { RESET_STATE_ACTION_TYPE } from 'store/resetState'

import { serviceAPI, categoryAPI, authAPI, callbackAPI, orderAPI, profileAPI, addressAPI, mapAPI } from 'services'
import authReducer from 'store/features/authSlice'
import basketSlice from 'store/features/basketSlice'
import orderSlice from 'store/features/orderSlice'

const reducers = {
  auth: authReducer,
  basket: basketSlice,
  order: orderSlice,
  [serviceAPI.reducerPath]: serviceAPI.reducer,
  [categoryAPI.reducerPath]: categoryAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [callbackAPI.reducerPath]: callbackAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  [profileAPI.reducerPath]: profileAPI.reducer,
  [addressAPI.reducerPath]: addressAPI.reducer,
  [mapAPI.reducerPath]: mapAPI.reducer,
}
const combinedReducer = combineReducers<typeof reducers>(reducers)
const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = { basket: state?.basket } as RootState
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      unauthenticatedMiddleware,
      serviceAPI.middleware,
      categoryAPI.middleware,
      authAPI.middleware,
      callbackAPI.middleware,
      orderAPI.middleware,
      profileAPI.middleware,
      addressAPI.middleware,
      mapAPI.middleware,
    ),
  preloadedState: loadState(),
  devTools: process.env.NODE_ENV === 'development',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof combinedReducer>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
