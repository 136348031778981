import { baseQuery } from 'helpers/baseQuery';
import { createApi } from '@reduxjs/toolkit/query/react'

interface TCallback {
  phone: string
  comment?: string
}

export const callbackAPI = createApi({
  reducerPath: 'callbackAPI',
  baseQuery,
  endpoints: build => ({
    callback: build.mutation({
      query: (body: TCallback) => ({
        url: 'CallbackEnquiries',
        method: 'post',
        body,
      })
    }),
  })
})