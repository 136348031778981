import router from 'next/router'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { resetStateAction } from 'store/resetState';
import type { RootState } from 'store'
import { ROUTES } from 'constants/routes';

export const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
})

export const unauthenticatedMiddleware: Middleware = ({
  dispatch
}) => (next) => (action) => {
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    alert('Авторизуйтесь для доступа к вашим заказам в личном кабинете')
    router.push(ROUTES.signIn)
    dispatch(resetStateAction())
  }

  return next(action);
};