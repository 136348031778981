import { TOrderEdit } from 'types/Order';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from 'store'

const initialState: {
  unsavedOrder: null | TOrderEdit
} = {
  unsavedOrder: null
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setUnsavedOrder: (state, { payload }: PayloadAction<TOrderEdit>) => {
      state.unsavedOrder = payload
    },
    deleteUnsavedOrder: (state) => {
      state.unsavedOrder = null
    },
  },
})

export const { setUnsavedOrder, deleteUnsavedOrder } = orderSlice.actions
export const selectUnsavedOrder = (state: RootState) => state.order.unsavedOrder
export default orderSlice.reducer