import { Provider } from 'react-redux'
import Head from 'next/head'
import type { AppProps } from 'next/app'

import { store } from 'store'
import { saveState } from 'store/browser-storage';
import { debounce } from "debounce";

import 'bootstrap/dist/css/bootstrap.css'
import 'animate.css'
import 'styles/styles.sass'
import { useRouter } from 'next/router';

store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

const CURRENT_URL = 'https://4b.ru'

const getCanonical = (path: string) => {
  const fullURL = new URL(path, CURRENT_URL)
  return`${fullURL.origin}${fullURL.pathname}`
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const canonical = getCanonical(router.asPath)
  console.log(canonical)
  return (
    <Provider store={store}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="canonical" href={canonical} />
      </Head>
      <Component {...pageProps} />
    </Provider>
  )
}

export default MyApp
