import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'helpers/baseQuery';
import { TOrderEditItems, TOrder, TOrderEdit } from 'types/Order';
import { TObjectID } from 'types/common';

export const orderAPI = createApi({
  reducerPath: 'orderAPI',
  baseQuery,
  endpoints: build => ({
    create: build.mutation<TOrder, TOrderEdit>({
      query: body => ({
        url: 'Order',
        method: 'post',
        body
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        try {
          const { data: createdOrder } = await queryFulfilled
          dispatch(
            orderAPI.util.updateQueryData('get', undefined, draftOrders => {
              draftOrders.unshift(createdOrder)
            })
          )
        } catch (error) { }
      },
    }),
    edit: build.mutation<TOrder, { id: number, body: TOrderEdit }>({
      query: ({ id, body }) => ({
        url: `Order/edit/${id}`,
        method: 'post',
        body
      })
    }),
    get: build.query<TOrder[], void>({
      query: () => ({
        url: 'Order',
      }),
      transformResponse: (list: TOrder[]) => list?.sort((a, b) => b.id - a.id),
    }),
    getOne: build.query<TOrder, TObjectID>({
      query: (id) => ({
        url: `Order/${id}`,
      })
    }),
    checkPrice: build.mutation<{ deliveryPrice: number }, { orderId?: number, items: TOrderEditItems[] }>({
      query: body => ({
        url: `Order/delivery-price`,
        method: 'post',
        body
      })
    }),
    getPickupDates: build.query<string[], void>({
      query: () => ({
        url: 'Order/available-pickup-date',
        method: 'get',
      }),
    }),
    getOffloadDates: build.mutation<string[], { pickupDate: string, serviceIds: number[] }>({
      query: (body) => ({
        url: 'Order/available-offload-date',
        method: 'post',
        body
      }),
    }),
  }),
})